<template>
  <el-container  class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <!--<img src="../assets/logo.png" alt />-->
        <span class="title">智慧分析</span>
      </div>
      <div class="change">
        <!-- 切换公司 项目 -->
        <el-dropdown @command="handleCompany" v-if="role == '超级管理员'">
          <span class="el-dropdown-link"
            ><span style="font-size: 15px"
              ><span style="color: lightblue">当前公司:</span
              >{{ companyName || "无" }}</span
            ><i class="el-icon-arrow-down el-icon--right"></i
          ></span>
          <template #dropdown>
            <el-dropdown-menu
              :index="item.companyId"
              v-for="item in companyList"
              :key="item.companyId"
            >
              <el-dropdown-item :command="item">{{
                item.companyName
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <el-dropdown @command="handleItem">
          <span class="el-dropdown-link"
            ><span style="font-size: 15px"
              ><span style="color: lightblue">当前项目:</span
              >{{ itemName || "无" }}</span
            ><i class="el-icon-arrow-down el-icon--right"></i
          ></span>
          <template #dropdown>
            <el-dropdown-menu
              :index="item.itemId"
              v-for="item in itemList"
              :key="item.itemId"
            >
              <el-dropdown-item :command="item">{{
                item.itemName
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>

      <div>
        <span style="font-size: 15px"
          ><span style="color: lightblue">岗位:</span>{{ role }}</span
        >
        <span class="poniter" @click="logout" style="font-size: 15px"
          >退出</span
        >
      </div>
      <!-- 退出 -->
    </el-header>

    <!-- 页面主体区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="'180px'"> 
        <!--<div class="toggle-button" @click="toggleCollapse">
          <i :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
        </div>-->
        <!-- 侧边栏菜单区域 -->
        <el-menu 
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409EFF"
          unique-opened
          :collapse="isCollapse"
          v:collapse-transition="false"
          :router="true"
          :default-active="activePath"
        >
          <!-- 一级菜单 -->
          <el-submenu
            :index="item.id + ''"
            v-for="item in menuList"
            :key="item.id"
          >
            <template #title>
              <i :class="iconsObj[item.id]"></i><span>{{ item.authName }}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item
              :index="'/' + subItem.path"
              v-for="subItem in item.children"
              :key="subItem.id"
              @click="saveNavState('/' + subItem.path)"
            >
              <template #title>
                <i class="el-icon-s-opportunity"></i
                ><span>{{ subItem.authName }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 右侧内容主体 -->
      <el-main >
        <router-view :itemName="itemName"></router-view>
        <div  class="footer">
          <div class="copy">版权所有Copyright &copy智慧分析</div>
          <div>
            <a
              style="text-decoration: none; color: black"
              href="https://beian.miit.gov.cn/"
              target="_blank"
              >已备案:粤ICP备2021054626号</a
            >
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
//let elementResizeDetectorMaker = require("element-resize-detector");
import global_ from "../components/global.vue";
export default {
  data() {
    return {
      companyName: "无",
      companyId: global_.companyId,
      role: "admin",
      itemName: "无",
      baseExcel: [],
      Query: {
        query: "",
        pagenum: 1,
        pagesize: 5,
      },
      baseTotal: 0,
      // 左侧菜单数据
      menuList: [],
      // 菜单 id对应icon
      iconsObj: {
        "010": "el-icon-office-building",
        "020": "el-icon-postcard",
        "030": "el-icon-s-custom",
        "040": "el-icon-s-operation",
        "050": "el-icon-edit-outline",
        "060": "el-icon-s-check",
        "070": "el-icon-data-analysis",
        "080": "el-icon-setting",
      },
      isCollapse: false,
      activePath: "/own",
      phone: "",
      // 顶部导航栏 显示用户所属的公司项目
      companyList: [],
      itemList: [],
      // 当前的公司id、项目id
      activeCompany: 0,
      activeItem: 0,

      superAdmin: [
        {
          id: "010",
          authName: "公司管理",
          children: [{ id: "011", authName: "公司列表", path: "company" }],
        },
        {
          id: "020",
          authName: "项目管理",
          children: [{ id: "021", authName: "项目列表", path: "item" }],
        },
        {
          id: "030",
          authName: "用户管理",
          children: [{ id: "031", authName: "用户列表", path: "user" }],
        },
        {
          id: "040",
          authName: "权限管理",
          children: [{ id: "041", authName: "岗位权限管理", path: "power" }],
        },
        {
          id: "050",
          authName: "数据填报",
          children: [
            { id: "051", authName: "表格操作", path: "reportExcel" },
            { id: "053", authName: "填报记录", path: "reportRecord" },
          ],
        },
        {
          id: "060",
          authName: "数据审核",
          children: [{ id: "063", authName: "审核记录", path: "reviewRecord" }],
        },
        {
          id: "070",
          authName: "数据展示",
          children: [
            { id: "071", authName: "项目情况", path: "showAll" },
            { id: "072", authName: "单位工程情况", path: "showPart" },
          ],
        },
        {
          id: "080",
          authName: "个人中心",
          children: [{ id: "081", authName: "用户信息", path: "own" }],
        },
      ],
      companyAdmin: [
        {
          id: "020",
          authName: "项目管理",
          children: [{ id: "021", authName: "项目列表", path: "item" }],
        },
        // ,{ id: "022", authName: "项目成员管理", path: "itemMember" }
        {
          id: "030",
          authName: "用户管理",
          children: [{ id: "031", authName: "用户列表", path: "user" }],
        },
        {
          id: "040",
          authName: "权限管理",
          children: [{ id: "041", authName: "岗位权限管理", path: "power" }],
        },
        {
          id: "050",
          authName: "数据填报",
          children: [{ id: "053", authName: "填报记录", path: "reportRecord" }],
        },
        {
          id: "060",
          authName: "数据审核",
          children: [{ id: "063", authName: "审核记录", path: "reviewRecord" }],
        },
        {
          id: "070",
          authName: "数据展示",
          children: [
            { id: "071", authName: "项目情况", path: "showAll" },
            { id: "072", authName: "单位工程情况", path: "showPart" },
          ],
        },
        {
          id: "080",
          authName: "个人中心",
          children: [{ id: "081", authName: "用户信息", path: "own" }],
        },
      ],
      itemLeader: [
        {
          id: "020",
          authName: "项目管理",
          children: [
            { id: "022", authName: "项目成员管理", path: "itemMember" },
          ],
        },
        {
          id: "050",
          authName: "数据填报",
          children: [{ id: "053", authName: "填报记录", path: "reportRecord" }],
        },
        {
          id: "060",
          authName: "数据审核",
          children: [{ id: "063", authName: "审核记录", path: "reviewRecord" }],
        },
        {
          id: "070",
          authName: "数据展示",
          children: [
            { id: "071", authName: "项目情况", path: "showAll" },
            { id: "072", authName: "单位工程情况", path: "showPart" },
          ],
        },
        {
          id: "080",
          authName: "个人中心",
          children: [{ id: "081", authName: "用户信息", path: "own" }],
        },
      ],
      companyLeader: [
        {
          id: "050",
          authName: "数据填报",
          children: [
            { id: "051", authName: "表格操作", path: "reportExcel" },
            { id: "053", authName: "填报记录", path: "reportRecord" },
          ],
        },
        {
          id: "070",
          authName: "数据展示",
          children: [
            { id: "071", authName: "项目情况", path: "showAll" },
            { id: "072", authName: "单位工程情况", path: "showPart" },
          ],
        },
        {
          id: "080",
          authName: "个人中心",
          children: [{ id: "081", authName: "用户信息", path: "own" }],
        },
      ],
      TechnicalLeader: [
        {
          id: "050",
          authName: "数据填报",
          children: [
            { id: "051", authName: "表格操作", path: "reportExcel" },
            { id: "053", authName: "填报记录", path: "reportRecord" },
          ],
        },
        {
          id: "070",
          authName: "数据展示",
          children: [
            { id: "071", authName: "项目情况", path: "showAll" },
            { id: "072", authName: "单位工程情况", path: "showPart" },
          ],
        },
        {
          id: "080",
          authName: "个人中心",
          children: [{ id: "081", authName: "用户信息", path: "own" }],
        },
      ],
      contractor: [
        {
          id: "050",
          authName: "数据填报",
          children: [
            { id: "051", authName: "表格操作", path: "reportExcel" },
            { id: "052", authName: "参数表填报", path: "report" },
            { id: "053", authName: "填报记录", path: "reportRecord" },
          ],
        },
        {
          id: "070",
          authName: "数据展示",
          children: [
            { id: "071", authName: "项目情况", path: "showAll" },
            { id: "072", authName: "单位工程情况", path: "showPart" },
          ],
        },
        {
          id: "080",
          authName: "个人中心",
          children: [{ id: "081", authName: "用户信息", path: "own" }],
        },
      ],
      contractSupervisor: [
        {
          id: "050",
          authName: "表格操作",
          children: [{ id: "051", authName: "表格下载", path: "reportExcel" }],
        },
        {
          id: "060",
          authName: "数据审核",
          children: [
            { id: "061", authName: "表格审核", path: "reviewExcel" },
            { id: "063", authName: "审核记录", path: "reviewRecord" },
          ],
        },
        {
          id: "070",
          authName: "数据展示",
          children: [
            { id: "071", authName: "项目情况", path: "showAll" },
            { id: "072", authName: "单位工程情况", path: "showPart" },
          ],
        },
        {
          id: "080",
          authName: "个人中心",
          children: [{ id: "081", authName: "用户信息", path: "own" }],
        },
      ],
      progressor: [
        {
          id: "050",
          authName: "数据填报",
          children: [
            // { id: "051", authName: "表格操作", path: "reportExcel" },
            { id: "052", authName: "数据填报", path: "report" },
            { id: "053", authName: "填报记录", path: "reportRecord" },
          ],
        },
        {
          id: "070",
          authName: "数据展示",
          children: [
            { id: "071", authName: "项目情况", path: "showAll" },
            { id: "072", authName: "单位工程情况", path: "showPart" },
          ],
        },
        {
          id: "080",
          authName: "个人中心",
          children: [{ id: "081", authName: "用户信息", path: "own" }],
        },
      ],
      progressSupervisor: [
        {
          id: "050",
          authName: "表格操作",
          children: [{ id: "051", authName: "表格下载", path: "reportExcel" }],
        },
        {
          id: "060",
          authName: "数据审核",
          children: [
            { id: "061", authName: "表格审核", path: "reviewExcel" },
            { id: "062", authName: "数据审核", path: "review" },
            { id: "063", authName: "审核记录", path: "reviewRecord" },
          ],
        },
        {
          id: "070",
          authName: "数据展示",
          children: [
            { id: "071", authName: "项目情况", path: "showAll" },
            { id: "072", authName: "单位工程情况", path: "showPart" },
          ],
        },
        {
          id: "080",
          authName: "个人中心",
          children: [{ id: "081", authName: "用户信息", path: "own" }],
        },
      ],
      documenter: [
        {
          id: "050",
          authName: "数据填报",
          children: [
            { id: "051", authName: "表格操作", path: "reportExcel" },
            { id: "052", authName: "数据填报", path: "report" },
            { id: "053", authName: "填报记录", path: "reportRecord" },
          ],
        },
        {
          id: "070",
          authName: "数据展示",
          children: [
            { id: "071", authName: "项目情况", path: "showAll" },
            { id: "072", authName: "单位工程情况", path: "showPart" },
          ],
        },
        {
          id: "080",
          authName: "个人中心",
          children: [{ id: "081", authName: "用户信息", path: "own" }],
        },
      ],
      documentSupervisor: [
        {
          id: "050",
          authName: "表格操作",
          children: [{ id: "051", authName: "表格下载", path: "reportExcel" }],
        },
        {
          id: "060",
          authName: "数据审核",
          children: [
            { id: "061", authName: "表格审核", path: "reviewExcel" },
            { id: "062", authName: "数据审核", path: "review" },
            { id: "063", authName: "审核记录", path: "reviewRecord" },
          ],
        },
        {
          id: "070",
          authName: "数据展示",
          children: [
            { id: "071", authName: "项目情况", path: "showAll" },
            { id: "072", authName: "单位工程情况", path: "showPart" },
          ],
        },
        {
          id: "080",
          authName: "个人中心",
          children: [{ id: "081", authName: "用户信息", path: "own" }],
        },
      ],
      experimenter: [
        {
          id: "050",
          authName: "数据填报",
          children: [
            { id: "051", authName: "表格操作", path: "reportExcel" },
            { id: "052", authName: "数据填报", path: "report" },
            { id: "053", authName: "填报记录", path: "reportRecord" },
          ],
        },
        {
          id: "070",
          authName: "数据展示",
          children: [
            { id: "071", authName: "项目情况", path: "showAll" },
            { id: "072", authName: "单位工程情况", path: "showPart" },
          ],
        },
        {
          id: "080",
          authName: "个人中心",
          children: [{ id: "081", authName: "用户信息", path: "own" }],
        },
      ],
      experimentSupervisor: [
        {
          id: "050",
          authName: "表格操作",
          children: [{ id: "051", authName: "表格下载", path: "reportExcel" }],
        },
        {
          id: "060",
          authName: "数据审核",
          children: [
            { id: "061", authName: "表格审核", path: "reviewExcel" },
            { id: "062", authName: "数据审核", path: "review" },
            { id: "063", authName: "审核记录", path: "reviewRecord" },
          ],
        },
        {
          id: "070",
          authName: "数据展示",
          children: [
            { id: "071", authName: "项目情况", path: "showAll" },
            { id: "072", authName: "单位工程情况", path: "showPart" },
          ],
        },
        {
          id: "080",
          authName: "个人中心",
          children: [{ id: "081", authName: "用户信息", path: "own" }],
        },
      ],
    };
  },
  created() {
    this.itemName = window.sessionStorage.getItem("itemName");
    if (window.localStorage.getItem("autoLogin") === "true") {
      window.sessionStorage.setItem(
        "satoken",
        window.localStorage.getItem("satoken")
      );
    }
    this.isLogin();
  },
  methods: {
    async isLogin() {
      const { data: res } = await this.$http.get("user/isLogin");
      if (res.code == 200) {
        this.activePath = window.sessionStorage.getItem("activePath");
        // if(this.activePath != "/own")
        //   this.$router.push(this.activePath);
        // this.getComPanyList();
        this.getInformation();
      } else {
        this.$store.commit("autoLogin", false);
        this.$message.error(res.msg);
        window.sessionStorage.clear();
        this.$router.push("/login");
      }
    },
    async getComPanyList() {
      const { data: res } = await this.$http.get("company/getCompanyList", {
        params: {
          query: "",
          pagenum: 1,
          pagesize: 5,
        },
      });
      if (res.code != 200) {
        return this.$message.error(res.msg);
      }
      // console.log(res)
      this.companyList = res.data.list;
    },
    async getInformation() {
      const { data: res } = await this.$http.get("user/getInfo");
      if (res.code != 200) {
        return this.$message.error(res.msg);
      }
      if (res.data.curCompanyId == 1) {
        this.companyName = "gpnu";
      }
      if (res.data.curItemId == 1) {
        this.itemName = "建设工程";
      }
      window.sessionStorage.setItem("roleName", res.data.roleName);
      window.sessionStorage.setItem("activeItem", res.data.curItemId);
      window.sessionStorage.setItem("curCompanyId", res.data.curCompanyId);
      this.activeItem = res.data.curItemId;
      this.itemList = res.data.itemList;
      console.log( this.itemList ,11);
      switch (res.data.roleName) {
        case "超级管理员":
          this.menuList = this.superAdmin;
          this.role = res.data.roleName;
          this.getComPanyList();
          break;
        case "公司管理员":
          this.menuList = this.companyAdmin;
          this.role = res.data.roleName;
          this.companyName = window.sessionStorage.getItem("companyName");
          break;
        case "合约员":
          this.menuList = this.contractor;
          this.role = res.data.roleName;
          this.isPass("contractor/getReportedBaseList");
          break;
        case "合约主管":
          this.menuList = this.contractSupervisor;
          this.role = res.data.roleName;
          break;
        case "施工员":
          this.menuList = this.progressor;
          this.role = res.data.roleName;
          break;
        case "施工主管":
          this.menuList = this.progressSupervisor;
          this.role = res.data.roleName;
          break;
        case "资料员":
          this.menuList = this.documenter;
          this.role = res.data.roleName;
          break;
        case "资料主管":
          this.menuList = this.documentSupervisor;
          this.role = res.data.roleName;
          break;
        case "试验员":
          this.menuList = this.experimenter;
          this.role = res.data.roleName;
          this.isPass("experimenter/getReportedExperiment");
          break;
        case "试验主管":
          this.menuList = this.experimentSupervisor;
          this.role = res.data.roleName;
          break;
        case "项目负责人":
          this.menuList = this.itemLeader;
          this.role = res.data.roleName;
          break;
        case "公司负责人":
          this.menuList = this.companyLeader;
          this.role = res.data.roleName;
          break;
        case "技术负责人":
          this.menuList = this.companyLeader;
          this.role = res.data.roleName;
          break;
        default:
          this.$message.error("岗位未知");
      }
    },
    //检测是否有不通过记录
    isPass(newurl) {
      this.Query.curItemId = window.sessionStorage.getItem("activeItem");
      let url = newurl;
      this.CheckPassed(this.Query, url);
    },
    async CheckPassed(param, url) {
      const { data: res } = await this.$http.get(url, { params: param });
      if (res.code !== 200) return this.$message.error(res.msg);
      console.log("通过了", res.data.list);
      let isManzu = this.checked(res.data.list);
    },
    //检查函数
    checked(arr) {
      let isManzu = arr.every(function (item, index, arr) {
        if (item.status == 2) {
          return false;
        }
      });
      if (!isManzu) {
        alert("表格有记录检查不合格，请尽快修改");
      }
    },
    handleCompany(command) {
      this.companyName = command.companyName;
      this.$message.info("当前您选择的公司为：" + command.companyName);
      // localStorage.comtemid = command;
      // global_.companyId = localStorage.comtemid
      window.sessionStorage.setItem("curCompanyId", command.companyId);
      window.sessionStorage.setItem("companyName", command.companyName);
      if (
        window.location.href == "http://localhost:8080/#/item" ||
        window.location.href == "http://localhost:8080/#/user" ||
        window.location.href == "http://175.24.116.94/#/item" ||
        window.location.href == "http://175.24.116.94/#/user"
      ) {
        location.reload();
      }

      // console.log(this.$store)
      this.$store.commit("setCompanyId", command);
    },

    handleItem(command) {
      let currentRole = window.sessionStorage.getItem("roleName");
      if (
        currentRole != "超级管理员" &&
        currentRole != "公司管理员" &&
        currentRole != "公司负责人"
      ) {
        alert("您无权切换项目");
      } else {
        this.$router.push({name:'main'})
        this.itemName = command.itemName;
        this.$message.info("当前您选择的项目id为：" + command.itemName);
        window.sessionStorage.setItem("activeItem", command.itemId);
        window.sessionStorage.setItem("itemName", command.itemName);
        let hrefCUr = window.location.href;
        if (
          hrefCUr == "http://localhost:8080/#/itemMember" ||
          hrefCUr == "http://175.24.116.94/#/itemMember" ||
          hrefCUr == "http://localhost:8080/#/user" ||
          hrefCUr == "http://175.24.116.94/#/user"
        ) {
          location.reload();
        }
      }
    },
    logout() {
      this.$http.get("user/logout");
      // if (res.code != 200) {return this.$message.error(res.msg);}
      this.$store.commit("logout");
      this.$router.push("/adver");
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    saveNavState(activePath) {
      window.sessionStorage.setItem("activePath", activePath);
      this.activePath = activePath;
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 5px;
  color: black;
  left: 55px;
}
.copy {
  margin-bottom: 10px;
}
.copyright {
  width: 100%;
  height: 34px;
  line-height: 34px;
  text-align: center;
  position: fixed;
  bottom: 5px;
}
.copyright:link {
  color: black;
}
.copyright:hover {
  color: blue;
}
.home-container {
  height: 100%;
  width:100%;
}
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
  box-sizing: border-box;
  width:100%;
  width:100vw;
  overflow: hidden;
  > div {
    display: flex;
    align-items: center;
    span {
      margin-left: 12px;
    }
    img {
      margin-left: 5px;
      height: 50px;
      width: 50px;
    }
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #fff;
  }
  // .el-button {
  //   width: 80px;
  //   padding: 10px;
  // }
  .title {
    width: 200px;
  }
  .change {
    height: 30px;
    width: 350px;
    padding-left: 10px;
  }
}
.el-aside {
  background-color: #333744;
  height:100%;
  width:100%;
  position: absolute;

  .el-menu {
    border-right: none;
  }
}
.el-main {
  background-color: #eaedf1;
  position: absolute;
   left:180px;
    top:60px;
    width:calc(100vw - 180px);
    height:100%;
    overflow-x: hidden;
    overflow-y: scroll;
}
.el-main::-webkit-scrollbar {
  display: none;
}
.iconfont {
  margin-right: 10px;
}
.toggle-button {
  background-color: #4a5064;
  font-size: 15px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.poniter {
  cursor: pointer;
}
</style>
