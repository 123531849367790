<template>
</template>

<script>
  const companyId = localStorage.comtemid;
  export default {
    companyId,
  }
</script>

<style>
</style>
